import Carousel from '@/js/components/carousel'

export default class Featured {
  constructor() {
    this.element = document.querySelector('#featured-pages')
    if (!this.element) return

    this.active = null
    this.headerImage = this.element.querySelector('.image')

    this.carousel = new Carousel(
      '#featured-pages .carousel',
      {
        wrapAround: true,
        prevNextButtons: false,
        // adaptiveHeight: true,
        autoPlay: 4000,
        selectedAttraction: 0.02,
        pageDots: false,
        on: {
          select: (index) => {
            this.updateHeaderImage(index)
          },
        },
      },
      () => {}
    )
  }

  updateHeaderImage(index) {
    if (this.active) this.active.classList.remove('is-visible')
    this.active = this.headerImage.children[index]
    this.active.classList.add('is-visible')
  }
}
