import Sizes from '@/js/components/sizes'
import { CLazyHandler } from '@/js/handlers'
import L from 'leaflet/dist/leaflet'
import 'leaflet/dist/leaflet.css'

export default class Map {
  constructor() {
    this.containers = document.querySelectorAll('.map-component')
    if (!this.containers) return

    this.instances = []

    this.containers.forEach((e) => {
      const data = JSON.parse(e.querySelector('script').textContent).filter(
        (e) => e.latitude && e.longitude
      )

      if (data.length)
        new CLazyHandler(e, () => this.init(data, e.querySelector('.map')), {
          threshold: 0.2,
        })
      else e.classList.add('is-hidden')
    })
  }

  init(data, component) {
    const instance = L.map(component, {
      maxZoom: 17,
      minZoom: 8,
      scrollWheelZoom: false,
      tap: !Sizes.isMobile,
      dragging: !Sizes.isMobile,
      // zoomControl: false,
      // keyboard: false,
      // doubleClickZoom: false,
    })

    L.tileLayer(
      'https://tiles.stadiamaps.com/tiles/stamen_toner_lite/{z}/{x}/{y}{r}.png',
      {
        maxZoom: 20,
        attribution:
          '&copy; <a href="https://stadiamaps.com/" target="_blank">Stadia Maps</a> &copy; <a href="https://stamen.com/" target="_blank">Stamen Design</a> &copy; <a href="https://openmaptiles.org/" target="_blank">OpenMapTiles</a> &copy; <a href="https://www.openstreetmap.org/about" target="_blank">OpenStreetMap</a> contributors',
        detectRetina: true,
      }
    ).addTo(instance)

    const bounds = L.latLngBounds(
      data.map((e) => [e.latitude, e.longitude])
    ).pad(0.2)

    instance.fitBounds(bounds)
    const markers = []

    data.forEach((e) => {
      const marker = L.marker([e.latitude, e.longitude], {
        icon: this.getMarkerIcon(e.link_gmaps || e.link),
      }).bindTooltip(e.name, {
        direction: 'top',
      })

      instance.addLayer(marker)
      markers.push(marker)
    })

    this.instances.push({ instance, markers })
  }

  getMarkerIcon(link) {
    return L.divIcon({
      html: `
        <a href="${link}" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <circle cx="15" cy="15" r="12.5" stroke="currentColor" stroke-width="5" fill="white" />
          </svg>
        </a>
      `,
      iconSize: [30, 30],
      iconAnchor: [15, 15],
      tooltipAnchor: [0, -15],
    })
  }
}
