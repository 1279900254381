import '@/betacity/scss/base.scss'
import Accordion from '@/js/components/accordion'
import ConsentCookies from '@/js/components/consent-cookies'
import Carousel from '@/js/components/carousel'
import CarouselFullscreen from './components/carousel_fullscreen'
import Featured from './components/featured_pages'
import LazyLoadEmbedBlocks from '@/js/components/lazy-load-embed'
import Map from './components/map'
import Modals from './components/modals'
import DialogLink from './components/dialog-link'
import Navbar from './components/navbar'
import Search from './components/search'
import Sizes from '@/js/components/sizes'
// import 'iconify-icon'

class CAPP {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      console.log('[Betacity base online]')

      const accordion = new Accordion('.block-accordion')
      const featured = new Featured()
      const map = new Map()
      const navbar = new Navbar()
      const modals = new Modals()
      const search = new Search()
      const dialog = new DialogLink()

      const carousel = new Carousel('.carousel.is-default', {
        prevNextButtons: false,
        pageDots: false,
        imagesLoaded: true,
        lazyLoad: 2,
        setGallerySize: false,
        wrapAround: true,
      })

      const carouselFullscreen = new CarouselFullscreen()
      const lazyLoadEmbed = new LazyLoadEmbedBlocks()

      this.initScrollTo()
    })
  }

  initScrollTo() {
    const btns = document.querySelectorAll('.js-tothetop')

    btns.forEach((btn) => {
      btn.addEventListener('click', () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      })
    })

    if (Sizes.isMobile) {
      const element = document.querySelector('.js-mobile-in-view')

      if (element) {
        const parent = element.parentNode

        parent.scrollTo(
          element.offsetLeft - element.offsetWidth * (5 / 6) + 20,
          0
        )
      }
    }
  }
}

const APP = new CAPP()
export default APP

window.consentCookies = new ConsentCookies()
