class Api {
  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      this.csrf = document.getElementsByName('csrfmiddlewaretoken')[0].value
    })
  }

  request(path, opts) {
    return fetch(path, opts).then((response) => {
      return response.status !== 500 ? response : ''
    })
  }

  post(path, opts) {
    return this.request(path, {
      ...opts,
      method: 'POST',
      headers: { 'X-CSRFToken': this.csrf },
    }).then((result) => (result.status === 502 ? result : result.json()))
  }

  get(path, opts) {
    return this.request(path, opts).then((result) => result.json())
  }

  search(query) {
    return this.request('/ro/search/?query=' + query, {
      method: 'POST',
      headers: { 'X-CSRFToken': this.csrf },
    }).then((result) => result.text())
  }

  getIndex(path, page, query) {
    return this.request(
      path +
        '?' +
        new URLSearchParams({
          page,
        }) +
        (query ? '&' + query : '')
    ).then((result) => result.text())
  }

  getEventsDays(query) {
    return this.request('/api/get-calendar-evenimente/?' + query).then(
      (result) => result.json()
    )
  }

  getSubmission(pk) {
    return this.get(`/api/form-submissions/${pk}/`)
  }
}

const API = new Api()
export default API
