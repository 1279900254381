import Flickity from 'flickity'
import Carousel from '@/js/components/carousel'
import 'flickity-fullscreen'

export default class CarouselFullscreen {
  constructor() {
    this.triggers = document.querySelectorAll('[data-fullscreen]')
    this.carousels = {}
    this.active = null

    document.addEventListener('keyup', (event) => {
      if (event.key == 'Escape' && this.active) {
        this.exitFullscreen()
      }
    })

    this.triggers.forEach((e) => {
      e.addEventListener('click', (event) => {
        event.preventDefault()

        const id = e.dataset.fullscreen
        let index = e.dataset.index

        if (index == null) {
          const carousel = e.closest('.carousel.is-default')

          if (carousel)
            index = Flickity.data(
              carousel.querySelector('.carousel-container')
            )?.selectedIndex
        }

        if (!this.carousels[id]) {
          this.carousels[id] = new Carousel(
            '.carousel.is-fullscreen[data-id="' + id + '"]',
            {
              prevNextButtons: false,
              pageDots: false,
              imagesLoaded: true,
              lazyLoad: 2,
              wrapAround: true,
              setGallerySize: false,
            },
            () => {
              this.viewFullscreen(id, index)
            },
            () => {
              this.exitFullscreen()
            }
          )
        } else {
          this.viewFullscreen(id, index)
        }
      })
    })
  }

  viewFullscreen(id, index) {
    this.active = this.carousels[id]
    this.active.elements[0].classList.remove('is-hidden')
    this.active.instances[0].viewFullscreen()
    this.active.instances[0].selectCell(parseInt(index), false, true)
  }

  exitFullscreen() {
    // console.log('exit')
    this.active.instances[0].exitFullscreen()
    this.active.elements[0].classList.add('is-hidden')
    this.active = null
  }
}
